//SKILS SECTION
.skills {
  display: block;
}
.skillsDescription {
  max-width: 640px;
  margin: auto;
}
.skillsContainer {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#skills {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
#skills::-webkit-scrollbar {
  display: none;
}
.box {
  position: relative;
  width: 200px;
  margin-left: 20px;
  margin-right: 20px;
  height: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.box .percent {
  position: relative;
  width: 150px;
  height: 150px;
}
.box .percent svg {
}

.box .percent svg circle {
  /* width:20px; */
  fill: none;
  stroke-width: 8;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  stroke-linecap: round;
}
.box .percent svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #f3f3f3;
}
.box .percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.box .percent .number h2 {
  font-size: 3em;
}

.box .percent .number span {
  font-size: 24px;
}
.box .text {
  padding: 10px 0 0;
  color: #999;
  font-weight: 700;
  font-size: 1.5em;
}

// Technologies
.technologies {
  display: flex;
  justify-content: center;
  margin-top: 175px;
}
.technologiesContent {
  display: flex;
  align-items: center;
}
.technologiesContainer {
  width: 1200px;
  width:80%;
  padding: 20px;
  cursor: pointer;
}
.swiperContainer {
}
.swiperButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.technologyItem {
  height: 200px;
  width: 200px;
  background: white;
  border: 3px lightgray solid;
  border-radius: 15px;
  margin: auto;
  position: relative;
}
.logoContainer {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}
.logoImg {
  max-width: 150px;
}

@media only screen and (max-width: 1200px) {
  .technologies{
    display:block;
    text-align: center;
  }

  .technologiesContent{
    display:block;
  }
  .swiperContainer{
    // width:750;
  }
}

// @media only screen and (max-width: 1000px) {
//   .technologies{
//     display:block;
//     text-align: center;
//   }

//   .technologiesContainer{
//     width:300;
//   }
//   .swiperContainer{
//     width:1000;
//   }
// }
// @media only screen and (max-width: 800px) {
//   .technologies{
//     display:block;
//     text-align: center;
//   }

//   .technologiesContainer{
//     width:300;
//   }
//   .swiperContainer{
//     width:500;
//   }
// }
