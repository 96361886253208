$first-color: #eef3f6;
$second-color: #f3f4f9;
$third-color: #fcf9f4;
$fourth-color: #d5e2f7;
$fifth-color: #f7eee1;
$sixth-color: #f1e7e7;
$seventh-color: #f3ddef;

.works {
  margin-top: calc(90px + 100px);
  // padding-right: 150px;
  // padding-left: 150px;
  width: 100%;
}

.projectWrapper:nth-child(even) {
  justify-content: left;
}
.projectWrapper {
  display: flex;
  justify-content: right;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 100px;
  text-align: left;
}
.project {
  width: 100%;
  background: green;
  // width: 1350px;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  background: $third-color;
}
.right {
  justify-content: right;
}
.left {
  justify-content: left;
}
.imageContainer {
  // background:blue;
  margin-right: 15px;
  margin-left: 50px;
}
.projectImg {
  max-width: 600px;
}

.projectDescription {
  max-width: 600px;
  margin-right: 50px;
  margin-left: 15px;
  // background:yellow;
}
.technologies {
  list-style: none;
  display: flex;
  justify-content: right;
}
.technologies li {
  margin-left: 15px;
  font-weight: bold;
}
.projectText {
  // width:600px;
}

.links {
  text-align: right;
}
.linkIcon {
  margin-left: 15px;
}

@media only screen and (max-width: 1200px) {
  .projectWrapper {
    display: block;
    justify-content: right;
    // margin:0;
    // text-align:center;
  }
  .project {
    display: block;
  }
  .projectDescription {
    margin: auto;
  }
  .imageContainer {
    display: flex;
    justify-content: center;
  }
  .projectImg {
    margin: auto;
  }
}
