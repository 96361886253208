.experience {
  // margin-top:150px;
  // margin:auto;
}
.experienceContainer {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 30px;
  padding: 20px;
  width: 80%;
  margin: auto;
  // background:green;
  // box-shadow: rgba(50, 50, 36, 0.1) 100px 50px 300px 50px,
  //   rgba(0, 0, 0, 0.3) -100px -50px 300px -50px;
}

.experienceContainer > * {
  width: 300px;
  height: 300px;
  overflow: hidden;
  background: white;
  margin: auto;
  display: flex;
  align-items: center;
  // background:gray;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px 5px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  // padding: 15px;
}
// .self
.projectImg {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.textItem {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .experienceContainer {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1000px) {
  .experienceContainer {
    grid-template-columns: 1fr 1fr;
  }
}
