$first-color: #6bd7f4;
$second-color: #93e7cf;
$third-color: #8e8dd9;
$fourth-color: #f0b57d;

.firstSection {
  background: #f4f6f6;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.secondSection {
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.thirdSection {
  background: #f4f6f6;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 50px;
  padding-bottom: 50px;
}
p {
  color: #909090;
}
h2 {
  font-size: 2.3em;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}
.description {
  width: 600px;
  margin: auto;
  margin-bottom: 40px;
}
.info {
  // padding: 5%;
}
.aboutContent {
  margin-top: 80px;
  min-height: 800px;
  background: white;
  display: flex;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  background: url("../assets/circle-scatter-haikei.svg") no-repeat center right;
}
.aboutSummary {
  text-align: left;
  margin-right: 50px;
}
.aboutSummary h1 {
  font-size: 4em;
}
.aboutImage {
  display: block;
  float: right;
  width: 700px;
  margin-left: 50px;
}
.aboutText {
  text-align: left;
}

.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  z-index: 10;
}
.noShowBar {
  display: none;
}

.nav a {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 1.7em;
  text-decoration: none;
  color: black;
}
.socials > * {
  margin-right: 15px;
  margin-left: 10px;
}
.logo {
  width: 125px;
  margin-left: 30px;
}
.chatButton {
  height: 65px;
  width: 150px;
  background: #6c63ff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5em;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}
#resumeButton {
  background: white;
  color: #4b82e3;
  font-weight: 600;
  // background:#4b82e3;
}
.chatButtonContainer {
  display: flex;
}

.section {
  // margin-top: 275px;
  // margin:auto;
}

@media only screen and (max-width: 750px) {
  .socials{
    display:none;
  }
}
@media only screen and (max-width: 1250px) {
  .aboutImage{
    display:none;
  }
}